import shortid from "shortid";
import {
	base64decodeUrl,
	getParameterByName,
	timezoneOffset,
	getCookieByName,
} from "@utils/Browser";

import { observable, action, computed, toJS, runInAction } from "mobx";
import required from "@libs/required";

import { createLeadValidation } from "../@data/validation";
import { leadService } from "../@data/service";

const { createLead, findPartnerOpportunity } = leadService;
const { SITE_NAME, GOOGLE_ADS_ID, GA_TRACKING_ID } = process.env;

class Lead {
	@observable answers = [];

	key = shortid.generate();
	service;
	midtail;
	providerId;
	picks = [];
	preSelectedAnswerKeys = [];
	sold = {};

	constructor(rootStore) {
		this.rootStore = rootStore;
		this.addSelectedAnswer = this.addSelectedAnswer.bind(this);
		this.removeSelectedAnswer = this.removeSelectedAnswer.bind(this);
		this.preSelectedAnswerKeys = this.parseBase64EncodedAnswerKeys(
			getParameterByName("answers")
		);
	}

	@observable _zipcode;
	@computed get zipcode() {
		return toJS(this._zipcode);
	}

	get timezoneOffset() {
		return timezoneOffset();
	}

	// get preSelectedAnswerKeys() {
	// 	const base64encodedAnswers = getParameterByName("answers");
	// 	// const { leadFunnelStore } = this.rootStore.stores;
	// 	//const questions = leadFunnelStore.Questions.value || [];
	// 	// const answers = questions.map((question) => question.answers).flat();
	// 	if (!base64encodedAnswers /*&& !answers.length*/) return [];
	// 	let response = [];
	// 	if (base64encodedAnswers)
	// 		response = JSON.parse(base64decodeUrl(base64encodedAnswers));
	// 	// if (answers.length > 0)
	// 	// 	answers.forEach((answer) => response.push(answer.key));
	// 	return response;
	// }

	get preSelectedQuestionIds() {
		const { leadFunnelStore } = this.rootStore.stores;
		const questions = leadFunnelStore.Questions.value || [];
		return questions
			.filter(
				(question) =>
					!!question.answers.find((answer) =>
						this.preSelectedAnswerKeys.includes(answer.key)
					)
			)
			.map((question) => question._id);
	}

	get email() {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Client.email;
	}

	get allSelectedPros() {
		const { leadFunnelStore, moreProsStore } = this.rootStore.stores;
		return [
			leadFunnelStore.Provider.value,
			...moreProsStore.matches
				.filter((match) => this.picks.includes(match.provider._id))
				.map((match) => match.provider),
		].filter(Boolean);
	}

	get value() {
		let xxTrustedFormCertUrl;
		if (SITE_NAME === "homeguide") {
			xxTrustedFormCertUrl = document.getElementById(
				"xxTrustedFormCertUrl"
			)?.value;
			if (!xxTrustedFormCertUrl)
				xxTrustedFormCertUrl = document.getElementById(
					"xxTrustedFormCertUrl_0"
				)?.value;
		}

		return {
			key: this.key,
			xxTrustedFormCertUrl,
			track: {
				content: getCookieByName("utm_content"),
				source: getCookieByName("utm_source"),
				campaign: getCookieByName("utm_campaign"),
			},
			timezoneOffset: this.timezoneOffset,
			zipcode: this.zipcode,
			answers: toJS(this.answers).map((answer) => ({
				...answer,
				__state: undefined,
			})),
			picks: [this.providerId, ...this.picks].filter(Boolean),
			service: this.service.name,
			midtail: this.midtail?._id,
			leadActionType: this.leadActionType,
			provider: this.providerId,
		};
	}

	parseBase64EncodedAnswerKeys(base64encodedAnswers) {
		if (!base64encodedAnswers /*&& !answers.length*/) return [];
		let response = [];
		if (base64encodedAnswers)
			response = JSON.parse(base64decodeUrl(base64encodedAnswers));
		// if (answers.length > 0)
		// 	answers.forEach((answer) => response.push(answer.key));
		return response;
	}

	init({
		provider,
		service,
		midtail,
		zipcode,
		leadActionType,
		base64EncodedAnswerKeys,
	}) {
		const { leadFunnelStore } = this.rootStore.stores;
		this.providerId = provider?._id;
		this.service = service;
		this.midtail = midtail;
		this.leadActionType = leadActionType;
		this.updateZipcode(zipcode);
		if (base64EncodedAnswerKeys)
			this.preSelectedAnswerKeys = this.parseBase64EncodedAnswerKeys(
				base64EncodedAnswerKeys
			);
		if (this.preSelectedAnswerKeys.length === 0) return;
		this.preSelectedAnswerKeys.forEach((answerKey) => {
			const question =
				leadFunnelStore.Questions.findQuestionByAnswerKey(answerKey);
			if (!question) return;
			const answer = question.answers.find(
				(answer) => answer.key === answerKey
			);
			if (!answer) return;
			this.addSelectedAnswer(answer._id, answer.value);
		});
	}

	findAnswerByQuestionId(questionId = required`questionId`) {
		const index = this.answers.findIndex(
			({ question }) => question === questionId
		);
		return {
			value: toJS(this.answers[index]),
			index,
		};
	}

	_findQuestionBySelectedAnswerId(
		selectedAnswerId = required`selectedAnswerId`
	) {
		const { leadFunnelStore } = this.rootStore.stores;
		return leadFunnelStore.Questions.findQuestionByAnswerId(selectedAnswerId);
	}

	findAnswerBySelectedAnswerId(selectedAnswerId = required`selectedAnswerId`) {
		const question = this._findQuestionBySelectedAnswerId(selectedAnswerId);
		const { value: leadAnswer } = this.findAnswerByQuestionId(question._id);
		if (!leadAnswer) return;
		return toJS(leadAnswer);
	}

	addSelectedAnswer(
		selectedAnswerId = required`selectedAnswerId`,
		value,
		tag_value,
		__state
	) {
		// if (typeof value === "string")
		// 	value = value?.replace(/<div [^>]+>(.*?)<\/div>/g, "");
		const hasLeadAnswer = !!this.findAnswerBySelectedAnswerId(selectedAnswerId);
		const question = this._findQuestionBySelectedAnswerId(selectedAnswerId);
		const selectedAnswer = question.answers.find(
			(answer) => answer._id === selectedAnswerId
		);

		try {
			let leadAnswer;
			if (hasLeadAnswer) {
				this.removeSelectedAnswer(selectedAnswerId);
				const { value } = this.findAnswerByQuestionId(question._id);
				leadAnswer = value;
			}

			if (leadAnswer) {
				this.updateAnswer({
					question: question._id,
					description: question.description,
					answers: Array.from(
						new Set([...leadAnswer.answers, selectedAnswer._id])
					),
					values: Array.from(
						new Set([...leadAnswer.values, value || selectedAnswer.value])
					),
					__state,
					tag_name: (question.tag || {}).name,
					tag_values: Object.assign(
						leadAnswer.tag_values,
						selectedAnswer.tag_index
							? {
									[`${selectedAnswer.tag_index}`]:
										tag_value || value || selectedAnswer.value,
							  }
							: {}
					),
				});
				return;
			}

			this.updateAnswer({
				question: question._id,
				description: question.description,
				answers: [selectedAnswerId],
				values: [value || selectedAnswer.value],
				tag_name: (question.tag || {}).name,
				__state,
				tag_values: Object.assign(
					{},
					selectedAnswer.tag_index
						? {
								[`${selectedAnswer.tag_index}`]:
									tag_value || value || selectedAnswer.value,
						  }
						: {}
				),
			});
		} catch (err) {
			throw err;
		}
	}

	removeSelectedAnswer(selectedAnswerId = required`selectedAnswerId`) {
		const leadAnswer = this.findAnswerBySelectedAnswerId(selectedAnswerId);
		const question = this._findQuestionBySelectedAnswerId(selectedAnswerId);
		const selectedAnswer = question.answers.find(
			(answer) => answer._id === selectedAnswerId
		);

		try {
			if (leadAnswer) {
				const answerIndex = leadAnswer.answers.findIndex(
					(answerId) => answerId === selectedAnswerId
				);
				if (answerIndex === 0 && leadAnswer.answers.length === 1)
					this.removeAnswer(question._id);
				else
					this.updateAnswer({
						question: question._id,
						description: question.description,
						answers: leadAnswer.answers.filter((_, i) => i !== answerIndex),
						values: leadAnswer.values.filter((_, i) => i !== answerIndex),
						tag_name: (question.tag || {}).name,
						tag_values: Object.assign(
							leadAnswer.tag_values,
							selectedAnswer.tag_index
								? { [`${selectedAnswer.tag_index}`]: undefined }
								: {}
						),
					});
			}
		} catch (err) {
			throw err;
		}
	}

	@action
	removeAnswer(questionId = required`questionId`) {
		const { index } = this.findAnswerByQuestionId(questionId);
		if (~index) {
			this.answers = this.answers.filter((_, i) => i !== index);
		}
	}

	@action
	updateAnswer(data = required`data`) {
		try {
			createLeadValidation.validateSyncAt("input.answers[0]", {
				input: { answers: data },
			});
			const { index } = this.findAnswerByQuestionId(data.question);
			if (~index) {
				this.answers[index] = data;
				return;
			}
			this.answers.push(data);
		} catch (err) {
			throw err;
		}
	}

	@action
	updateZipcode(val = "") {
		if (val.length > 5) return;
		this._zipcode = val;
	}

	async create() {
		try {
			const { record } = await createLead({ input: this.value });
			fbq("track", "Lead");
			gtag("event", "conversion", {
				send_to: `${GOOGLE_ADS_ID}/l6VlCOWu7HEQ1uW3zwM`,
				transaction_id: record._id,
			});
			gtag("event", "submit_a_lead", {
				event_name: "lead",
				event_action: "lead",
				event_category: "Clients",
				send_to: [
					`${GOOGLE_ADS_ID}/l6VlCOWu7HEQ1uW3zwM`, // Google Ads Conversion ID
					`${GA_TRACKING_ID}`, // GA4 Property ID
				],
			});
			uetq.push("event", "submit_lead_form", {});
			if (["homeguide"].includes(SITE_NAME)) {
				const sold = await findPartnerOpportunity({ leadKey: record.key });
				this.sold = sold;
			}
		} catch (err) {
			throw err;
		}
	}
}

export default Lead;
