import React, { memo } from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

let TaloIcon = (props) => (
	<SvgIcon {...props} viewBox={"0 0 96 96"}>
		<title>Talo</title>
		<path
			d="M68.83,72.07c0,11.16-9.05,20.21-20.21,20.21s-20.21-9.05-20.21-20.21,9.05-20.21,20.21-20.21,20.21,9.05,20.21,20.21"
			fill="#1776ff"
		/>
		<path
			d="M70.22,44.14H25.78c-11.16,0-20.21-9.05-20.21-20.21S14.62,3.72,25.78,3.72h44.43c11.16,0,20.21,9.05,20.21,20.21s-9.05,20.21-20.21,20.21"
			fill="#1776ff"
		/>
	</SvgIcon>
);

TaloIcon = memo(TaloIcon);
TaloIcon.displayName = "TaloIcon";
TaloIcon.muiName = "TaloIcon";

export default TaloIcon;
