import React, { useEffect, useRef } from "react";
import ProTypes from "prop-types";
import { observer } from "mobx-react";

import useStores from "@hooks/useStores";
import useStyles from "@hooks/useStyles";
import useError from "@hooks/useError";

import Background from "../Shared/Background";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";

import Box from "@material-ui/core/Box";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";

import PhoneIcon from "@material-ui/icons/Phone";
import InputAdornment from "@material-ui/core/InputAdornment";
import Alert from "@material-ui/lab/Alert";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { adjective as proAdjective } from "@language/provider";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@custom/TextField";
import Divider from "@material-ui/core/Divider";

const { BRAND_NAME, SITE_NAME } = process.env;

const PhoneZip = observer(({ secondary }) => {
	const classes = useStyles("LeadFunnel");
	const { phoneZipStore: store } = useStores();
	const [ErrorTemplate, onError] = useError();

	const phoneRef = useRef();
	const zipcodeRef = useRef();

	useEffect(() => {
		store.init().catch(onError);
	}, []);

	const nextHandler = async () => {
		try {
			await store.next(phoneRef, zipcodeRef);
		} catch (err) {
			onError(err);
		}
	};

	const onChangeTextNotificationHandler = (e) => {
		store.updateTextNotification(e.target.checked);
	};

	const prevHandler = () => store.prev();
	const phoneOnChangeHandler = (e) => store.updatePhone(e.target.value);
	const zipOnChangeHandler = (e) => store.updateZipcode(e.target.value);
	return (
		<React.Fragment>
			<Header />
			<DialogContent className={classes.dialogContent}>
				<Background nextHandler={nextHandler}>
					<Grid container>
						<Grid item xs={false} sm={2} />
						<Grid item xs={12} sm={8}>
							<div
								className={
									secondary
										? classes.questionHeaderBox2
										: classes.questionHeaderBox
								}
							>
								<div
									className={
										secondary ? classes.questionHeader2 : classes.questionHeader
									}
								>
									Review the zip and add your contact info
								</div>
							</div>
							<div>
								{/* <Box mb={8}>
									<Alert
										variant="outlined"
										severity="success"
										icon={<LockOutlinedIcon fontSize="inherit" />}
									>
										{`This number is only given to ${proAdjective.plural} you may want to hire. Thank you for sharing yours.`}
									</Alert>
								</Box> */}
								<Box mb={5}>
									<Box fontWeight={600} mb={1.5}>
										Zip code
									</Box>
									<TextField
										inputRef={zipcodeRef}
										fullWidth={true}
										type={"zipcode"}
										value={store.zipcode}
										placeholder="Zip code"
										onChange={zipOnChangeHandler}
										variant={"outlined"}
										helperText="Double-check your zip code!"
									/>
								</Box>
								<Box mb={5}>
									<Box fontWeight={600} mb={1.5}>
										Phone number
									</Box>
									<TextField
										inputRef={phoneRef}
										//size={"small"}
										fullWidth
										groupId={"signup-form"}
										type={"tel"}
										onChange={phoneOnChangeHandler}
										value={store.phone || ""}
										variant="outlined"
										placeholder={"(555) 555-5555"}
										id="tel"
										name="tel"
										helperText={`We only share your info with ${proAdjective.plural} you select.`}
										// helperText={
										// 	<div>
										// 		You may receive a verification code if we don't
										// 		recognize this number. Standard rates{" "}
										// 		<Link
										// 			rel="noopener noreferrer"
										// 			href="/terms-of-service"
										// 			target={"_blank"}
										// 		>
										// 			apply
										// 		</Link>
										// 		.
										// 	</div>
										// }
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<PhoneIcon />
												</InputAdornment>
											),
										}}
									/>
								</Box>

								<FormControl>
									<FormControlLabel
										className={classes.rememberMe}
										control={
											<Checkbox
												defaultChecked={store.textNotification}
												onChange={onChangeTextNotificationHandler}
												name="texts"
												color="primary"
											/>
										}
										label="Text me updates"
									/>
									<FormHelperText>
										Stay informed with text messages from {BRAND_NAME}.{" "}
										<Link
											rel="noopener noreferrer"
											href="/terms-of-service#sms"
											target={"_blank"}
										>
											Terms apply
										</Link>
										.
									</FormHelperText>

									{SITE_NAME === "homeguide" && (
										<>
											<Box py={4}>
												<Divider light />
											</Box>

											<FormHelperText>
												<Box lineHeight={1.5}>
													By clicking Next I agree to{" "}
													<Link
														href="https://legal.angi.com/#contract-bjq4r3d6f"
														target="_blank"
													>
														Angi Terms
													</Link>{" "}
													and{" "}
													<Link
														href="https://legal.angi.com/#privacy-policy"
														target="_blank"
													>
														Privacy Policy
													</Link>
													, and I agree that Angi and parties calling on its
													behalf may use automated technology to deliver
													marketing calls and texts regarding pro matches for my
													project and future projects to the number I provided.
													I understand that consent is not a condition of
													service.
												</Box>
											</FormHelperText>
										</>
									)}

									{SITE_NAME === "tutors" && (
										<>
											<Box py={4}>
												<Divider light />
											</Box>

											<FormHelperText>
												<Box lineHeight={1.5}>
													By clicking Next I agree to{" "}
													<Link
														href="https://www.gradepotentialtutoring.com/terms-use"
														target="_blank"
													>
														Grade Potential Terms
													</Link>{" "}
													and{" "}
													<Link
														href="https://www.gradepotentialtutoring.com/privacy-policy"
														target="_blank"
													>
														Privacy Policy
													</Link>
													, and I agree that Grade Potential and parties calling
													on its behalf may use automated technology to deliver
													marketing calls and texts regarding tutoring services
													to the number I provided. I understand that consent is
													not a condition of service.
												</Box>
											</FormHelperText>
										</>
									)}
								</FormControl>
							</div>
						</Grid>
						<Grid item xs={false} sm={2} />
					</Grid>
				</Background>
			</DialogContent>
			<Footer nextHandler={nextHandler} prevHandler={prevHandler} />
			<ErrorTemplate />
		</React.Fragment>
	);
});

PhoneZip.propTypes = {
	service: ProTypes.shape({
		phrase: ProTypes.string.isRequired,
	}).isRequired,
};

export default PhoneZip;
